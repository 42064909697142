import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';
import { clearUserData, getUserData } from '../utils/userData';
import getString from '../utils/get-string';

class Logout extends React.Component {
  render() {
    if (getUserData()) clearUserData()

    return (
      <Layout>
        <Seo title={getString(this.props.strings, "11245138")} />

        <Page type="success">
          <Header>{getString(this.props.strings, "11220926")}</Header>
          <Content>
            <h1 className="h2 text-transform-uppercase">
              ログアウトしました。 <br />
              またお会いしましょう！
            </h1>
            <div className="page-text-container" style={{ textAlign: 'left' }}>
              <p className="h6">
                楽しかったでしょう？ <br />
                また、オリジナル・ゴールデンラガーに <br />
                ついて勉強しにいらしてください。<br />
                ピルスナー・ウルケル・アンバサダーの <br />
                認定を得る努力を続けましょう！
              </p>
            </div>
          </Content>
          <Footer>
            <FooterLinkNext to="/login">{getString(this.props.strings, "11245158")}</FooterLinkNext>
          </Footer>
        </Page>
      </Layout>
    )
  }
}



export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11245138", "11245158", "11220926"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <Logout {...data} {...props} />}
  />
)
